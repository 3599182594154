<template>
  <div class="cms">
    <public-header :button="{url: 'login', text: $t('landing.merchant_signin')}"/>
    <hero/>
    <div class="container" id="lifes">
      <div class="cms__lifes">
        <div class="cms__lifes-cont">
          <h2>{{$t('cms.discover')}}</h2>
          <div class="cms__boxopt mg-t-30">
            <div class="cms__boxopt-cont">
              <h4>{{$t('cms.discover_t1')}}</h4>
              <p>{{$t('cms.discover_m1')}}</p>
            </div>
          </div>
          <div class="cms__boxopt mg-t-30">
            <div class="cms__boxopt-cont">
              <h4>{{$t('cms.discover_t2')}}</h4>
              <p>{{$t('cms.discover_m2')}}</p>
            </div>
          </div>
          <div class="cms__boxopt mg-t-30">
            <div class="cms__boxopt-cont">
              <h4>{{$t('cms.discover_t3')}}</h4>
              <p>{{$t('cms.discover_m3')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cms__rewards" id="rewards">
      <div class="container">
        <h2>{{$t('cms.rewards')}}</h2>
        <div class="cms__rewards-cont">
          <div class="cms__rewards-box">
            <h4>{{$t('cms.rewards_title')}}</h4>
            <div class="cms__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('cms.rewards_li1')}}</span>
            </div>
            <div class="cms__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('cms.rewards_li2')}}</span>
            </div>
            <div class="cms__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('cms.rewards_li3')}}</span>
            </div>
            <p>{{$t('cms.rewards_subtitle')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" id="help">
      <div class="cms__help">
        <img src="/images/landing/img3.png" class="cms__help-img"/>
        <div class="cms__help-cont">
          <h2>{{$t('cms.help')}}</h2>

          <h4>{{$t('cms.help_t1')}}</h4>
          <h6 v-html="$t('cms.help_m1')"/>

          <h4>{{$t('cms.help_t2')}}</h4>
          <h6 v-html="$t('cms.help_m2')"/>

          <h4>{{$t('cms.help_t3')}}</h4>
          <h6 v-html="$t('cms.help_m3')"/>
        </div>
      </div>
    </div>
    <public-footer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicHeader from '@/components/publicHeader/publicHeader'
import footer from '@/components/footer/footer'
import hero from '@/components/hero/hero'

export default {
  name: 'Cms',
  components: {
    publicHeader,
    hero,
    'public-footer': footer
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
